<template>
  <v-container>
    <v-card width="100%" height="auto" outlined>
      <v-form id=farm >
        <v-layout class="flex_mobile justify-center" >  
          <v-card outlined color="transparent" max-width="500px" class="pa-3">
            <div>{{farm_code}}</div>

            <v-flex xs12 lg7>
              <v-select dense outlined style="height: 50px;"
              id="company" 
              ref="companySelect" 
              label="경영체✽" 
              v-model="company"
              :items="companyItems"
              :menu-props="{ top: false, offsetY: true }"
              item-text="name"
              return-object
              @change="sameInfo=false"
            />
            </v-flex>

            <v-layout nowrap justify-center>
              <v-flex xs12 lg7>
                <v-text-field dense outlined style="height: 50px;" 
                :loading="loading"  
                id="farm_name" 
                ref="farm_name" 
                :label="bizstatus.code === 'CF02' || bizstatus.code === 'CF01' ? '농장명✽' : '사업장명✽'" 
                v-model="farm_name" 
              />
              </v-flex>

              <v-flex xs12 lg5>
                <v-row justify="center">
                <v-checkbox v-show="!farm_code"  
                  v-model="sameInfo"
                  color="#3a4f3f"
                  label="경영체와 동일"
                  @change="check($event)"
                  >
                </v-checkbox>
                </v-row>
              </v-flex>
            </v-layout>

            <v-layout wrap>
              <v-flex xs5 lg5>
              <div>
                <v-select dense outlined style="height: 50px;"
                v-model="country"
                ref="country" 
                label="표준시*"
                :items="timeZone_list"
                item-text="name"
                item-value="timeZone"
                :menu-props="{ top: false, offsetY: true }"
                return-object
                @change="comboTimeZone()"
                />
              </div>
              </v-flex>
              <v-flex xs7 lg7>
                <v-text-field dense outlined style="height: 50px;" class="ml-1"
                  id="post_no" 
                  ref="post_no" 
                  label="우편번호✽" 
                  v-model="post_no" 
                  onKeyup="this.value=this.value.replace(/[^0-9]/g,'');"
                  maxlength="5"
                />
            </v-flex>

            </v-layout>
            
            <v-text-field dense outlined style="height: 50px;"
              id="address_1st" 
              ref="address_1st" 
              label="기본주소✽" 
              v-model="address_1st" 
            />

            <v-text-field dense outlined style="height: 50px;"
              id="address_last" 
              ref="address_last" 
              label="상세주소(빌딩명,동호수,상호)" 
              v-model="address_last" 
            />

            <v-layout wrap>
              <v-flex xs6 lg6>
                <v-text-field dense outlined style="height: 50px;"
                id="email_id" 
                ref="email_id" 
                label="이메일" 
                v-model="email_id" 
                />
              </v-flex>

              <v-flex xs1 lg1>
                <div style="line-height: 35px; text-align: center;">
                  @
                </div>
              </v-flex>

              <v-flex xs5 lg5>
                <v-combobox dense outlined style="height: 50px;"
                id="email_host" 
                ref="email_host" 
                :items="['gmail.com','hotmail.com','naver.com','daum.net',]"
                :menu-props="{ top: false, offsetY: true }"
                placeholder="항목이 없으면 직접 입력하세요"
                v-model="email_host" 
              />
              </v-flex>
            </v-layout> 

            <v-text-field dense outlined style="height: 50px;"
              id="phone" 
              ref="phone" 
              label="전화번호✽" 
              placeholder="숫자만 입력"
              v-model="phone"
              @keyup="getPhoneMask(phone)"
            />

            <v-layout>
              <v-flex xs12 lg6>
                <v-text-field dense outlined style="height: 50px;" 
                  id="latitude" 
                  ref="latitude" 
                  label="위도✽" 
                  v-model="latitude" 
                  placeholder="위도 좌표를 구글지도등에서 확인후 입력하세요"
                  onKeyup="this.value=this.value.replace(/[^0-9.-]/g,'');"
                  maxlength="10"
                />
              </v-flex>

              <v-flex xs12 lg6 class="ml-1">
                <v-text-field dense outlined style="height: 50px;" 
                  id="longitude" 
                  ref="longitude" 
                  label="경도✽" 
                  v-model="longitude" 
                  placeholder="경도 좌표를 구글지도등에서 확인후 입력하세요"
                  onKeyup="this.value=this.value.replace(/[^0-9.-]/g,'');"
                  maxlength="11"
                />
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex xs12 lg6>
                <v-select dense outlined style="height: 50px;" 
                id="bizstatus" 
                ref="bizstatus" 
                label="업태✽" 
                v-model="bizstatus"
                :items="bizstatusItems"
                :menu-props="{ top: false, offsetY: true }"
                item-text="name"
                return-object
              />
              </v-flex>
              <v-flex xs12 lg6 class="ml-1">
                <v-text-field dense outlined style="height: 50px;" 
                id="business_no" 
                ref="business_no"
                label="사업자번호/법인번호" 
                placeholder="사업자 또는 법인 등록번호의 숫자만 입력"
                v-model="business_no" 
                @keyup="getRegMask(business_no)"
              />
              </v-flex>
            </v-layout>

            <div class="module_container">
              <h4>사용서비스</h4>
              <div class="module_set">
                <v-row >
                  <v-col>
                  <v-checkbox label="관리일지" v-model="dailyrecord" color="#3a4f3f" :disabled="this.$getters.userInfo.grade > 'UT02'"></v-checkbox>
                  </v-col>

                  <v-col>
                    <v-checkbox label="환기제어" v-model="fancontrol" color="#3a4f3f" :disabled="this.$getters.userInfo.grade > 'UT02'"></v-checkbox>
                  </v-col>

                  <v-col>
                    <v-checkbox label="악취제어" v-model="deodorizer" color="#3a4f3f" :disabled="this.$getters.userInfo.grade > 'UT02'"></v-checkbox>
                  </v-col>

                  <v-col>
                    <v-checkbox label="ESG" v-model="esg" color="#3a4f3f" :disabled="this.$getters.userInfo.grade > 'UT02'"></v-checkbox>
                  </v-col>
                </v-row>
              </div>
            </div>

            <v-layout>
              <v-flex xs12 lg6>
                <v-text-field dense outlined style="height: 50px;" 
                id="kepco_no" 
                ref="kepco_no" 
                label="한전고객번호" 
                placeholder="전력사용량 사용 시 입력"
                v-model="kepco_no" 
              />
              </v-flex>

              <v-flex xs12 lg6 class="ml-1">
                <v-text-field dense outlined style="height: 50px;" 
                id="farmUnique_no" 
                ref="farmUnique_no"
                label="농장식별번호" 
                placeholder="ESG사용 시 입력"
                v-model="farmUnique_no" 
              />
              </v-flex>
            </v-layout>

            <v-layout v-show="bizstatus.code === 'CF02'"  style="height: 40px" >
              <v-col>
                <div style="font-size:12px; height: 0px; width: 119px; margin-top: -10px">농장/사업장등록여부</div>
                <v-radio-group dense outlined row 
                  id="register_YN" 
                  v-model="register_YN"
                >
                  <v-radio
                    ref="register_y" 
                    label="Y" value="Y" color="#3a4f3f"
                  ></v-radio>
                  <v-radio
                    label="N" value="N" color="#88191a"
                  ></v-radio>
                </v-radio-group>
              </v-col> 

              <v-flex xs12 lg8 class="ml-1">
                <v-text-field dense outlined style="margin-top: 5px;"
                  id="register_no" 
                  ref="register_no" 
                  label="축산허가/등록번호"
                  placeholder="축산업허가증이 있는 경우만 입력하세요"
                  v-model="register_no" 
                />
              </v-flex>
            </v-layout>

            <div class="info_radio" v-show="bizstatus.code === 'CF02'">
              <div class="display">
                <div class="set_radio">
                  <v-radio-group dense outlined row 
                  id="owner_yn" 
                  ref="owner_yn" 
                  v-model="owner_yn">
                    <template v-slot:label>
                      <div class="set_name"> 소유자 근무여부 </div>
                    </template>
                    <v-radio label="Y" value="Y" color="#3a4f3f"/>
                    <v-radio label="N" value="N" color="#88191a"/>    
                  </v-radio-group>
                  </div>

                <div class="set_radio">
                  <v-radio-group dense outlined row 
                      id="haccp_yn" 
                      ref="haccp_yn" 
                      v-model="haccp_yn"
                    >
                      <template v-slot:label>
                        <div class="set_name"> HACCP 인증 </div>
                      </template>
                      <v-radio label="Y" value="Y" color="#3a4f3f"/>
                      <v-radio label="N" value="N" color="#88191a"/> 
                    </v-radio-group>
                  </div>
                </div>

              <div class="display">
                <div class="set_radio">
                  <v-radio-group dense outlined row
                    id="disinfection_yn" 
                    ref="disinfection_yn" 
                    v-model="disinfection_yn"
                  >
                    <template v-slot:label>
                      <div class="set_name"> 소독 여부 </div>
                    </template>
                    <v-radio label="Y" value="Y" color="#3a4f3f"/>
                    <v-radio label="N" value="N" color="#88191a"/> 
                  </v-radio-group>
                </div>
                
                <div class="set_radio mb-5">
                  <v-radio-group dense outlined row
                    id="fumigator_yn" 
                    ref="fumigator_yn" 
                    v-model="fumigator_yn"
                  >
                    <template v-slot:label>
                      <div class="set_name"> 방역 여부 </div>
                    </template>
                    <v-radio label="Y" value="Y" color="#3a4f3f"/>
                    <v-radio label="N" value="N" color="#88191a"/> 
                  </v-radio-group>
                </div>
             </div>
            </div>

            <v-layout>
              <v-flex xs6 lg6 class="mr-1">
                <v-text-field dense outlined style="height: 50px;" 
                id="etc3" 
                ref="etc3"
                label="축산업고유번호(5자)" 
                placeholder="숫자+영문조합"
                v-model="farmUnique_no" 
              />
              </v-flex>
              <v-flex xs6 lg6>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field dense outlined clearable style="height: 50px;"
                      id="deldate" 
                      ref="deldate" 
                      v-model="deldate"
                      label="사용중지일"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    no-title locale="ko-KR"
                    v-model="deldate"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
              
            <v-textarea outlined dense rows="2" name="input-7-4"
              label="비고"
              id="history" 
              ref="history" 
              v-model="history"
              value=""
              persistent-hint :hint="hint"
            ></v-textarea>

            <v-card-actions class="d-block">
              <v-row justify= "center">
                <v-btn class="rounded-pill normal-btn ma-2 mb-5" 
                  :loading= "loadingSave" 
                  id="save" 
                  ref="save" 
                  style="height: 35px; width: 100px;" 
                  type="submit" 
                  @click.prevent="save()">
                  저장
                </v-btn>  
                <v-btn class="rounded-pill new-btn ma-2 mb-5" 
                  id="newRegister" 
                  ref="newRegister" 
                  style="height: 35px; width: 100px;" 
                  @click="newRegister()">
                  신규
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>

          <v-card height="auto" class="dataTable ma-2" outlined color="transparent">
            <v-select dense outlined class="rounded-pill" style="width:50%; height:50px;"
              id="companySelect" 
              ref="companySelect" 
              label="경영체✽" 
              v-model="companySelect"
              :items="companyItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
              item-text="name"
              return-object
              @change="listFarms()"
              clearable
            />  
            
            <v-data-table dense height="530"
              fixed-header
              hide-default-footer
              :items-per-page= "500"
              :loading="loading"
              :headers="headers"
              :items="items"
              no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
              class="elevation-3"
              id="items" 
              ref="items" 
              @click:row="infoDetail"
              sort-by="farm_name"
              item-key="farm_cd"
              single-select
            >

            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color=#3a4f3f
                    medium
                    @click="childBuildings(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-share-variant
                  </v-icon>
                </template>
                <span class="tooltip">하위돈방/위치</span>
              </v-tooltip>
            </template>
          </v-data-table>

          <v-row justify= "center" class="mx-auto ma-2 pa-2  rounded-lg">
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-btn rounded-pill class="elevation-3 ma-3 rounded-pill normal-btn" style="width: 135px;"
                id="listFarms" 
                ref="listFarms" 
                @click="listFarms()">
                <img src="@/assets/icon_refresh.svg" alt="새로고침" />
                <span class="ml-1"> 목록고침 </span>
              </v-btn>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
          </v-row>
          </v-card>
        </v-layout>
      </v-form>
    </v-card>

  </v-container>
</template>

<script>
import Apis from '@/api/apis'
import Common from "@/utils/custom/common.js";
import dateUtil from "@/utils/custom/dateProperty.js";
import timeZone_list from "@/assets/json/timezone.json";

export default {
  name: 'Farm',
  
  data: () => ({
    headers: [
      { text: '',align: 'center', sortable: false, value: 'actions', width: "50px", groupable: false },
      { text: '사업장명', value: 'farm_name', width: "150px" },
      { text: '코드', value: 'farm_cd', width: "50px" },
      { text: '경영체', align: 'left', sortable: true, value: 'company_name', width: "160px" },
      { text: '업태', align: 'left', sortable: true, value: 'biz_status', width: "90px"},
      { text: '전화번호', value: 'phone', width: "150px"},
      { text: '등록일', value: 'regdate', width: "120px" },
      { text: '사용중지일', value: 'deldate', width: "120px"},
      { text: '비고', value: 'history', width: "200px"},
    ],

    timeZone_list:timeZone_list,
    country:'',

    items : [],
    companySelect : {},
    company : {},
    companyItems : [],

    selectedFarm: {},
    routedFarm: "",

    farm_code : "",
    farm_name : "",

    bizstatus : {},
    bizstatusItems : Common.comboBaseCode("CF"),

    sameInfo:false,
 
    business_no: "",

    register_YN: "",
    register_no: "",
    kepco_no:"",
    farmUnique_no:"",
    post_no: "",
    address_1st: "",
    address_last: "",
    latitude: "",
    longitude: "",
    email_id: "",
    email_host: "",
    phone: "",
    owner_yn: "",
    haccp_yn: "",
    disinfection_yn: "",
    fumigator_yn: "",
    deldate: "",
    history: "",
    hint : "",
    etc3:"",
    
    dailyrecord: false,
    fancontrol: false,
    deodorizer: false,
    esg: false,

    // businessDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu1: false,
    menu2: false,

    loading: false,
    loadingSave: false,

    label_name: "사업장명✽",
  }),
  created() {
    this.comboCompanies();
    // console.log("param",this.$route.params);

    if (this.$route.params.code) {
        // console.log("param1",this.$route.params)
        this.company = this.$route.params;
      }

    else if (!this.isNull(this.$route.params)) {
      if (this.$route.params.company_cd) {
        // console.log("param2",this.$route.params)
        this.company = this.$route.params;
      } else {
        this.routedFarm = this.$route.params.farm_cd;
      }
    }
    this.listFarms();
    // sessionStorage.setItem("infoTab",1)
  },

  methods: {

    comboTimeZone(){
      if(this.timeZone_list && this.timeZone_list.timeZone){
        this.country = {name : this.country.name, timezone:this.country.timeZone}
      }
      // console.log(this.country)
    },
    check (e) {
      if(e) {
        if (!this.company.code) {
          alert("경영체를 선택해주세요");
          this.$store.commit("resMessage","경영체를 먼저 선택해주세요");
          // this.clearData();
          return;
        } 
        Apis.infoCompany({
          company_cd: this.company.code, 
          },(res) => {  
            if (res.result) {
              this.farm_name = res.data.company_name;
              this.post_no = res.data.post_no;
              this.address_1st = res.data.address_1st;
              this.address_last = res.data.address_last;
              let email = res.data.email1.split("@",2);
              this.email_id = email[0];
              this.email_host = email[1];
              this.phone = Common.getMask(res.data.phone1);
            } else {
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              console.log("API 호출 오류")
              alert(err);
              // console.log(err)
          }
        ) 
      } else {
        this.farm_name = "";
        this.post_no = "";
        this.address_1st = "";
        this.address_last = "";
        this.email_id = "";
        this.email_host = "";
        this.phone = "";
      }
    },
    clearData() {
      this.company = {},
      this.farm_code = "",
      this.farm_name = "",

      this.sameInfo = false,

      this.country={},
      
      this.bizstatus = {},
      this.business_no = "",

      this.register_YN = "",
      this.register_no = "",
      this.kepco_no = "",
      this.farmUnique_no="",
      this.post_no = "",
      this.address_1st = "",
      this.address_last = "",
      this.latitude = "",
      this.longitude = "",
      this.email_id = "",
      this.email_host = "",
      this.phone = "",
      this.owner_yn = "",
      this.haccp_yn = "",
      this.disinfection_yn = "",
      this.fumigator_yn = "",
      this.deldate = "",
      this.history = ""
      this.hint = "";
      this.dailyrecord = false;
      this.fancontrol = false;
      this.deodorizer = false;
      this.esg = false;
      this.etc3 = false;
    },
    checkData() {

      if (!this.company.code) {
        this.$refs.company.focus();
        return "상위 경영체를 선택입력하세요";
      }
      if (!this.bizstatus.code) {
        this.$refs.bizstatus.focus();
        return "업태를 선택입력하세요";
      }
      if (!this.farm_name.trim()) {
        this.$refs.farm_name.focus();
        return "사업장(농장/사업장)명을 입력하세요";
      }
      if (this.business_no.trim()) {
        if (!(Common.checkBizNo(this.business_no)) && !(Common.checkRegNo(this.business_no))) {
          this.$refs.business_no.focus();
          return "사업자/법인 등록번호가 유효하지 안습니다.";
        }  
      }  
      if (this.bizstatus && this.bizstatus.code === 'CF02') {
        if (!this.register_YN) {
          this.$refs.register_y.$el.children[1].focus()
          return "농장/사업장 등록여부를 선택하세요";
        }
      }
      if (!this.country) {
        this.$refs.country.focus();
        return "해당 농장의 국가를 선택하세요";
      }
      if (!this.address_1st.trim()) {
        this.$refs.address_1st.focus();
        return "기본주소를 입력하세요";
      }
      if (!Common.isNumeric(this.latitude)) {
        this.$refs.latitude.focus();
        return "위도 좌표를 구글지도등에서 확인후 입력하세요";
      }
      if (Number(this.latitude) > 38.6144 || Number(this.latitude) < 33.167884)   {
        this.$refs.latitude.focus();
        return "위도 범위가 아닙니다";
      }
      if (!Common.isNumeric(this.longitude)) {
        this.$refs.longitude.focus();
        return "경도 좌표를 구글지도등에서 확인후 입력하세요";
      }
      if (Number(this.longitude) > 130.919948 || Number(this.longitude) < 125.36977)   {
        this.$refs.longitude.focus();
        return "경도 범위가 아닙니다";
      }
      if (!this.phone.trim()) {
        this.$refs.phone.focus();
        return "전화번호를 입력하세요";
      }
      return "";
    },
    save() {
      // console.log("checkdata", this.checkData());
      this.$store.commit("resMessage","");
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        // alert(msgCheckdata);
        return;
      }
      this.loadingSave = true;
      if (this.farm_code) {
        // console.log("업데이트");
        Apis.updateFarm({
          farm_cd: this.farm_code,
          company_cd: this.company.code,
          farm_name: this.farm_name,
          business_no: this.business_no.replace(/[^0-9]/g, ''),
          biz_status: this.bizstatus.code,
          reg_yn: this.register_YN,
          reg_no: this.register_no.trim(),
          kepco_no: this.kepco_no.trim(),
          post_no: this.post_no,
          address_1st: this.address_1st.trim(),
          address_last: this.address_last.trim(),
          latitude: Number(this.latitude),
          longitude: Number(this.longitude),
          email1: this.email_id.trim() + "@" + this.email_host, 
          email2: "",
          phone1: this.phone.replace(/[^0-9]/g, ''),
          phone2: "",
          owner_yn: this.owner_yn,
          haccp_yn: this.haccp_yn,
          disinfection_yn: this.disinfection_yn,
          fumigator_yn: this.fumigator_yn,
          deldate: this.deldate,
          history: this.history.trim(),
          etc1: this.country.timeZone,
          etc2: this.farmUnique_no.replaceAll(' ', ''),
          etc3: this.etc3,
          dailyrecord: this.dailyrecord,
          fancontrol: this.fancontrol,
          deodorizer: this.deodorizer,
          reserve1: this.esg,
          },(res) => {  
            if (res.result) {
              this.loadingSave = false;
              this.$store.commit("resMessage",res.message);
              // this.$router.push({
              //           name: "ListFarms",
              //           params: {farm_cd: this.farm_code}
              // });
              this.clearData();
              this.listFarms();
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류",err)
              alert(err);
          }
        )
      } else {
        // console.log("인서트");
        Apis.insertFarm({
          company_cd: this.company.code,
          farm_name: this.farm_name,
          
          business_no: this.business_no.replace(/[^0-9]/g, ''),
          biz_status: this.bizstatus.code,

          reg_yn: this.register_YN,
          reg_no: this.register_no.trim(),
          kepco_no: this.kepco_no.trim(),
          post_no: this.post_no,
          address_1st: this.address_1st.trim(),
          address_last: this.address_last.trim(),
          latitude: Number(this.latitude),
          longitude: Number(this.longitude),
          email1: this.email_id.trim() + "@" + this.email_host, 
          email2: "",
          phone1: this.phone.replace(/[^0-9]/g, ''),
          phone2: "",
          owner_yn: this.owner_yn,
          haccp_yn: this.haccp_yn,
          disinfection_yn: this.disinfection_yn,
          fumigator_yn: this.fumigator_yn,
          deldate: this.deldate,
          history: this.history.trim(),
          etc1: this.country.timeZone,
          etc2: this.farmUnique_no.replaceAll(' ', ''),
          etc3: this.etc3,
          dailyrecord: this.dailyrecord,
          fancontrol: this.fancontrol,
          deodorizer: this.deodorizer,
          reserve1: this.esg,
          },(res) => {  
            if (res.result) {
              this.loadingSave = false;
              this.$store.commit("resMessage",res.message);
              this.clearData();
              this.listFarms();
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류",err)
              alert(err);
          }
        )
      }
    },

    listFarms() {
      this.$store.commit("resMessage","");
      this.loading = true;
      this.companySelect =  this.companySelect || {};

      Apis.listFarms({
        company_cd: this.companySelect.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd")
      } ,(res) => {  // 정상처리
        // console.log("res",res);
        res.data.forEach(element => {
          element.phone = Common.getMask(element.phone);
        });
        this.items = res.data;
        this.$store.commit("resMessage",res.message);
        this.loading = false;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("listFarms 호출 오류",err);
        this.$router.push("/");
        this.loading = false;
      }) 
    }, 
    childBuildings(value) {
      // alert("경옃체에 속한 농장/사업장목록으로")
      this.loadingTable = true;
      this.$router.push({
                        // path: `/farminfo/registerinfo/building2`,
                        name:'building',
                        params:
                          { company: {code: value.company_cd, name: value.company_name},
                            farm: {company_cd: value.company_cd, company_name: value.company_name, code: value.farm_cd, name: value.farm_name},}
                       });
      this.loadingTable = false;
    },
    infoDetail(value, row) {
      row.select(true)

      this.$store.commit("resMessage","");
      this.loading = true;
      this.clearData();

      Apis.infoFarm({
        // session_id: this.$getters.sessionId,
        farm_cd: value.farm_cd, 
        },(res) => {  
          if (res.result) {
            this.farm_code = res.data.farm_cd;
            // console.log("data=", res.data.company_cd,res.data.company_name);
            this.company = {code: res.data.company_cd.substr(0,11), name: res.data.company_cd.substr(11)};
            this.farm_name = res.data.farm_name;
            this.country = res.data.etc1;
            this.bizstatus = {code: res.data.biz_status.code, name: res.data.biz_status.name};
            this.business_no = Common.getRegMask(res.data.business_no);
            this.country = res.data.etc1;
            this.register_YN = res.data.reg_yn;
            this.register_no = res.data.reg_no;
            this.kepco_no = res.data.kepco_no;
            this.farmUnique_no = res.data.etc2;
            this.etc3 = res.data.etc3;
            this.post_no = res.data.post_no; 
            this.address_1st = res.data.address_1st;
            this.address_last = res.data.address_last;
            this.latitude = res.data.latitude;
            this.longitude = res.data.longitude;
            let email = res.data.email1.split("@",2);
            this.email_id = email[0];
            this.email_host = email[1];
            this.phone = Common.getMask(res.data.phone1);
            this.owner_yn = res.data.owner_yn;
            this.haccp_yn = res.data.haccp_yn;
            this.disinfection_yn = res.data.disinfection_yn;
            this.fumigator_yn = res.data.fumigator_yn;
            this.deldate = res.data.deldate;
            this.dailyrecord = res.data.dailyrecord;
            this.fancontrol = res.data.fancontrol;
            this.deodorizer = res.data.deodorizer;
            this.esg = res.data.reserve1;
            this.history = res.data.history;
            this.hint = res.data.regdate + "에 등록, " + res.data.chguser + "님이 " + res.data.chgdate + "에 최종 수정";
            this.loading = false;

            this.$store.commit("resMessage",res.message);
          } else {
            this.loading = false;
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            this.loading = false;
            console.log("API 호출 오류",err)
            alert(err);
        }
      ) 
    },

    comboCompanies() {
      Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];

            // console.log(res.data);
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("API 호출 오류",err)
            alert(err);
        }
      ) 
    },

    newRegister() {
      this.$store.commit("resMessage","");
      this.clearData();
      this.$refs.company.focus();
      this.$store.commit("resMessage","새로 등록할 준비가 되었습니다.");
    },

    getPhoneMask(val) {
      this.phone = Common.getMask(val)
    },
    getRegMask(val) {
      // let res = Common.getRegMask(val)
      this.business_no = Common.getRegMask(val)
    },

  }  // -----------methods 

}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 820px){
  .flex_mobile{
    flex-wrap: wrap;
  }
  .dataTable{
    width:100% !important;
  }
}
.dataTable{
  width:60%;
}
.v-data-table::v-deep{
  thead.v-data-table-header tr {
    th {
      background-color: #436251 !important;
      color: white !important;
      font-size:13px;
      height:40px !important;
    }
  }
}
.set_radio{
  height:30px;
}
.module_container{
  height:90px;
  margin-bottom:10px;
  padding:10px;
  border-radius:8px;
  border: 1.5px solid #a3a3a3;
}
.module_set{
  margin-top:-10px;
  display:flex;
}
.set_name{
  width:85px;
  font-weight:500;
  font-size:14px;
  letter-spacing: -1px;
}
.normal-btn{
  background-color: #3a4f3f !important;
  color:white;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
}
.new-btn{
  color:#3a4f3f !important;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
  background-color: rgb(248, 248, 248) !important;
}
.dialog-btn{
  color:white !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #88191a !important;
}
.display{
  display:flex;
  flex-direction: row;
}
::v-deep .v-input--checkbox .v-label{
  font-size: 14px;
  letter-spacing: -1px;
}

::v-deep .v-radio .v-label{
  font-size:14px;
  margin-left:-5px;
}
@media screen and (max-width: 768px){
.display{
  flex-direction: column;
}
::v-deep .v-input--checkbox .v-label{
  font-size: 13px;
  letter-spacing: -1px;
}
}



</style>
